.gift-voucher-resend-button .small-text {
	color: var(--ion-color-primary-contrast) !important;
}
.gift-voucher-user {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 20px;
	flex: 1;
	overflow: hidden;
}
