.milestone-rewards-wrapper {
	background-color: var(--ion-color-primary);
	padding: 15px;
	border-radius: var(--okx-box-radius);
}

.milestone-rewards-wrapper * {
	color: var(--ion-color-primary-contrast);
}

.milestone-rewards-wrapper > div {
	display: flex;
	align-items: center;
	padding: 5px 0;
	position: relative;
}
.milestone-rewards-wrapper > div:last-child {
	padding-bottom: 0;
}
.milestone-rewards-wrapper > div > *:last-child {
	flex: 1;
	text-align: end;
}
.milestone-rewards-wrapper ion-icon {
	height: 12px;
	width: 12px;
	padding: 3px;
	border: 2px solid var(--ion-color-primary-contrast);
	border-radius: 50%;
	margin-right: 10px;
	color: transparent !important;
}

.milestone-rewards-wrapper .reward-unlocked ion-icon {
	border: 2px solid var(--ion-color-success);
}
.milestone-rewards-wrapper .reward-unlocked * {
	color: var(--ion-color-success) !important;
}

.milestone-rewards-wrapper > div:not(:last-child):after {
	content: '';
	height: 11px;
	width: 2px;
	background-color: rgba(var(--ion-color-primary-contrast-rgb), 0.5);
	position: absolute;
	left: 10px;
	bottom: -5px;
}
