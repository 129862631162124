.loyalty-content.scan {
  padding-bottom: 60px;
}

.loyalty-content.scan h2 {
  margin-top: 20px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.044em;
  /* margin-left: 24px; */
}

.loyalty-title-wrap {
  text-align: left;
  padding-left: 30px;
}

.noQrCode {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.qr-holder {
  margin: 0 !important;
}

.qr-holder canvas {
  border: 10px solid var(--ion-color-white);
  border-radius: calc(var(--okx-box-radius) / 2);
}

/* end second tab */

.points-tab {
  background-position: 50% 100%;
  background-size: 100% auto;
  z-index: 1;
}

.verified-content {
  width: 190px;
  margin: 0 auto;
}

.points-tab .normal-text {
  font-weight: bold;
}

#main > ion-content.route-loyalty > .no-padding .loyalty-title-wrap > ion-text:last-child > span {
  font-weight: bold;
}

#main > ion-content.route-loyalty > .no-padding .absolute-content.points-tab img {
  width: 40%;
}

#main > ion-content.route-loyalty > .no-padding .absolute-content.points-tab .points {
  font-size: 4em;
}

.web #main > ion-content.route-loyalty > .no-padding {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto !important;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  height: var(--okx-modal-height);
  overflow: hidden;
  background-color: var(--ion-color-white);
}

.web .route-loyalty .tabs {
  position: relative;
  height: 100%;
  border-radius: inherit;
}

.web .route-loyalty .tabs ion-slides,
ion-slide {
  height: calc(100vh - 350px);
  min-height: 350px;
}

.web ion-content.route-loyalty {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.web ion-segment-button.segment-button-checked {
  border-bottom-color: var(--ion-color-secondary);
}

/* new */
.profile-points {
  font-weight: bold;
  font-size: 20px !important;
}

.loyalty-header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: var(--okx-loyalty-card-background);
  margin: var(--ion-padding) var(--ion-padding) 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  border-radius: var(--okx-box-radius);
  background-position: center;
  background-size: cover;
}
.loyalty-header *{
  color:  var(--okx-loyalty-card-color) !important;
}
.loyalty-header-content {
  flex-direction: column-reverse;
}
.web .loyalty-header-content {
  flex-direction: row;
}
.loyalty-header img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
}

.loyalty-header-content {
  display: flex;
  z-index: 2;
}
.loyalty-header-name {
  z-index: 2;
  margin-bottom: 5px;
}
.loyalty-header-content .qr-code h5 {
  color: var(--okx-loyalty-card-color);
}
.loyalty-header-content {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.loyalty-header-content > div {
  display: flex;
  justify-content: space-between;
}
.loyalty-header-content .qr-code {
  align-self: center;
  margin: 10px 0 var(--ion-padding) 0;
}
.web .loyalty-header-content .qr-code {
  margin: 0;
}
.web .loyalty-header-content > div {
  flex-direction: column;
  align-items: flex-start;
}

.loyalty-header-content > div > div:last-child .normal-text {
  text-align: end;
}
.web .loyalty-header-content > div > div:last-child .normal-text {
  text-align: start;
}
.loyalty-header .subtitle {
  text-align: center;
  font-size: var(--okx-title-font-size);
}
.web .loyalty-header .subtitle {
  text-align: start;
  margin-bottom: 10px;
}
.loyalty-header .subtitle,
.loyalty-header .normal-text,
.loyalty-header .small-text,
.loyalty-header .title {
  display: block;
}
.loyalty-history,
.loyalty-content,
.loyalty-redeem-voucher {
  padding: var(--ion-padding);
}
.loyalty-redeem-voucher {
  padding-top: 0;
}
.loyalty-history {
  padding-top: 0;
}
.thin-text {
  opacity: 0.7;
  font-size: var(--okx-small-text-font-size) !important;
}

.transactions-wrapper ion-col {
  padding: 0;
}
.transactions-wrapper > div:not(:last-child) {
  border-bottom: 1px solid var(--ion-color-gray);
}
.transactions-wrapper > div {
  padding: 15px;
}

.content-box,
.sent-gift-vouchers-wrapper .box-wrapper {
  background-color: var(--ion-color-light);
  padding: 0 10px;
}
.sent-gift-vouchers-wrapper .box-wrapper {
  padding: 0 !important;
}
.transactions-wrapper .content-box,
.loyalty-rewards-wrapper {
  margin-top: 10px;
  min-height: 70px;
  display: grid;
  align-items: center;
  position: relative;
}

.loyalty-content-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.points-balance-value {
  font-size: 24px !important;
  line-height: 1;
}
.loyalty-content-wrapper .loyalty-content-value {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
}
/* .border-color {
  color: var(--ion-color-border) !important;
} */
.loyalty-content-desc {
  margin-top: 10px;
}

.route-loyalty .scrollable-y {
  height: 100%;
}

.web .route-loyalty .scrollable-y {
  overflow-y: auto;
}


.loyalty-name {
  text-transform: uppercase !important;
}

ion-modal.modal-classic.loyalty-faq-modal .modal-classic-wrapper {
  padding: 0;
}
ion-modal.modal-classic.loyalty-faq-modal .modal-classic-wrapper .modal-classic-content {
  padding-left: var(--ion-padding);
  padding-right: var(--ion-padding);
}
.loyalty-faq-modal > .modal-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 0 !important;
  text-align: center;
  overflow: unset;
}
.loyalty-faq-modal .modal-classic-content {
  height: 100vh;
}
.loyalty-faq-modal .ios-modal-classic-content {
  padding-top: var(--okx-ios-modal-padding) !important;
}
.web .loyalty-faq-modal > .modal-wrapper {
  max-width: var(--okx-bsmodal-width-md);
  width: 100%;
  height: var(--okx-modal-height);
  border-radius: var(--okx-box-radius) !important;
  overflow: hidden;
}
.loyalty-points {
  font-size: 22px !important;
  margin-bottom: 0 !important;
  display: flex !important;
  align-items: center;
  line-height: 1;
}
.web .loyalty-points {
  line-height: unset;
}
.loyalty-points-value {
  line-height: 2;
}
.loyalty-points ion-icon {
  margin-left: 5px;
  cursor: pointer;
}
.loyalty-text-12 {
  font-size: 12px !important;
}

.loyalty-text-10 {
  font-size: 10px !important;
}
.transaction-points-wrapper {
	background-color: transparent;
	min-width: 50px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	border: 1px solid var(--ion-color-gray);
}
.transaction-points-wrapper .normal-text {
	line-height: 1;
	display: block;
	margin-right: 5px;
}
.route-loyalty .page-title {
  margin-left: var(--ion-padding);
}
.web .route-loyalty .page-title {
  margin-top: var(--ion-padding);
}
.loyalty-vouchers-wrapper {
  margin: var(--ion-padding);
}
.route-loyalty .redeem-voucher-field-wrapper {
  margin-bottom: var(--ion-padding);
}

ion-content.route-loyalty {
  overflow: hidden;
}
/* .route-loyalty .input-field-container .normal-text {
  font-size: var(--okx-subtitle-font-size);
  color: var(--ion-color-primary) !important;
} */
.route-loyalty .input-field-container,
.route-loyalty .redeem-gift-voucher-component-wrapper ion-button {
  margin-bottom: 0;
}
.stamps-wrapper {
  padding: 15px;
  border-radius: var(--okx-box-radius);
  margin-top: 15px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}
.stamps-wrapper:first-of-type {
  margin-top: 0 !important;
}
.stamps-content {
  margin: var(--ion-padding);
}
.stamps-wrapper .subtitle {
  margin-bottom: 10px;
}
.stamps-wrapper .subtitle,
.stamps-wrapper .small-text {
  color: inherit !important;
}
.stamps-box {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(5, 50px);
  grid-auto-rows: 50px;
  gap: 15px;

  justify-content: space-around;
}
.stamps-box > div {
  background-color: var(--ion-color-white);
  border-radius: 10px;
}

.stamps-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
}
.stamps-box .locked-stamp-photo img {
  visibility: hidden;
}

.web .stamps-box {
  grid-template-columns: repeat(4, 60px);
  grid-auto-rows: 60px;
}
.stamps-box-header {
  display: flex;
  justify-content: space-between;
}
.stamps-wrapper ion-icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.loyalty-validation-wrapper {
  margin: -15px calc(var(--ion-padding) + 10px) calc(var(--ion-padding));
  padding: 20px 0 5px;
  text-align: center;
  background-color: var(--ion-color-white);
  border-radius: 0 0 var(--okx-box-radius) var(--okx-box-radius);
  border: 1px solid var(--okx-loyalty-validation-wrapper-border);
}

.loyalty-validation-wrapper.invalid {
  background-color: var(--ion-color-danger);
  border: 1px solid var(--ion-color-danger);
  cursor: pointer;
}
.loyalty-validation-wrapper .normal-text {
  color: var(--ion-color-dark);
}
.wallet-button {
  margin: 0 auto var(--ion-padding);
  display: flex;
  --background: transparent;
}
.web .wallet-button {
  margin: 10px 0 0 0;
}
.wallet-button img {
  position: relative;
  width: 150px;
}
.web .wallet-button img {
  width: 150px;
}
.loyalty-heading {
  margin-bottom: 10px;
}
.redeem-voucher-btn {
  display: flex;
  justify-content: center;
}
.redeem-voucher-btn ion-button {
  margin-bottom: var(--ion-padding);
  width: 80%;
}
.grouped-vouchers-wrapper {
  margin-bottom: var(--ion-padding);
}

ion-content.route-loyalty .no-padding {
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
}

ion-content.route-loyalty .no-padding > .scrollable-y {
  flex: 1;
}

.web ion-content.route-loyalty .no-padding{
  min-height: unset !important;
}

.keyboard-is-visible {
  padding-bottom: 50% !important;
}
.loyalty-heading {
	margin-bottom: 10px;
}
.loyalty-header-groups {
	display: flex;
	flex-wrap: wrap;
	margin: 10px 0 5px;
	justify-content: center;
}
.web .loyalty-header-groups {
	justify-content: flex-start;
	margin-left: -5px;
}
.loyalty-header-groups .small-text {
	background-color: var(--ion-color-primary-contrast);
	color: var(--ion-color-primary) !important;
	padding: 1px 5px;
	margin: 5px;
	border-radius: 5px;
	display: block;
}