:root {
  --drawer-menu-marker-height: 40px;
}

.menu-enabled .list-md {
  padding-top: 0;
  padding-bottom: 0;
}

.drawer-menu .title-default {
  text-transform: uppercase;
  font-weight: bold;
  padding-inline-start: 0;
}
.drawer-menu ion-header {
  padding: 10px 0;
}
.web .drawer-menu ion-header {
  padding: 15px 0;
}
.drawer-menu ion-content,
.drawer-menu ion-footer {
  padding-left: var(--ion-padding);
  padding-right: var(--ion-padding);
}

.drawer-menu ion-header,
.drawer-menu ion-footer {
  background-color: var(--okx-menu-background);
}

.drawer-menu ion-footer {
  background-image: none;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  border-top: 1px solid var(--ion-color-gray);
}
.drawer-menu ion-footer .footer-link {
  --inner-border-width: 0;
  border-bottom: none;
}
.footer-link.nav-item {
  --detail-icon-color: transparent !important;
}
.drawer-menu ion-footer::before {
  background-image: none;
}

.drawer-menu .nav-logo {
  position: absolute;
  left: 0;
  background-position: center;
  top: 15px;
  width: 100%;
  height: 40px;
  background-size: 55%;
  background-repeat: no-repeat;
}

.drawer-menu .nav-item {
  --padding-start: 0;
  --min-height: 24px;
  cursor: pointer;
  padding: 5px 0;
  border-bottom: 1px solid var(--ion-color-gray);
  --detail-icon-color: var(--okx-box-wrapper-color);
}
.sidebar-menu-content ion-list > div > *:last-of-type .nav-item,
.sidebar-menu-content ion-list > div > .nav-item:last-of-type {
  border-bottom: none;
}
.drawer-menu .nav-item-last.nav-item {
  border-bottom: none;
}

.drawer-menu .nav-item.small-text {
  padding: 0.5px 0;
  font-weight: normal;
}

.drawer-menu .nav-item.small-text ion-icon {
  height: 20px;
  width: 20px;
  margin: 0 15px 0 5px;
}

.drawer-menu .nav-icon {
  font-family: var(--ion-font-primary);
  stroke: var(--okx-menu-icon-color);
}
.drawer-menu .nav-label {
  margin: 0 !important;
  --color: var(--okx-menu-color);
  color: var(--okx-menu-color);
  font-family: var(--ion-font-family-bold);
  font-size: var(--okx-bold-font-size);
}

.drawer-menu .nav-item.small-text > .nav-icon,
.drawer-menu .nav-item.small-text .strong-text {
  font-family: var(--ion-font-primary);
  --color: var(--okx-color-primary);
  font-weight: normal;
}

.drawer-menu ion-header ion-toolbar:last-child {
  --border-width: 0 0 0;
  --background: transparent;
}

.logo-5l {
  position: absolute;
  right: 16px;
  bottom: 8px;
}

ion-split-pane {
  --side-max-width: var(--okx-drawer-width);
  --side-min-width: var(--okx-drawer-width);
  --side-width: var(--okx-drawer-width);
  --border: 0 none;
}

.web ion-menu {
  --max-width: var(--okx-drawer-width);
  --background: var(--ion-color-white);
}

.drawer-menu {
  --border: 0 none;
  --width: 95%;
}

.drawer-menu ion-content {
  --background: var(--okx-menu-background);
}

.drawer-menu ion-list {
  color: var(--okx-color-black);
}

.drawer-menu ion-item {
  color: inherit;
}

.drawer-menu ion-icon {
  stroke: var(--okx-box-wrapper-color);
  --ion-color-base:var(--okx-box-wrapper-color) !important
}

.logo-5l > div {
  width: 100px;
  padding-top: 45%;
  background: url(../../assets/images/5loyalty-black.svg) transparent no-repeat 50% 50%;
  background-size: 85% auto;
}

ion-footer > ion-label[hidden] {
  display: block !important;
  color: var(--okx-box-wrapper-color) !important;
}

.collapse-drawer-icon,
ion-content.log-status {
  display: none;
}

.web .drawer-menu ion-header ion-toolbar {
  position: relative;
}

.web .drawer-menu .nav-logo {
  top: 0;
  width: calc(100% - 100px);
  margin: 0 50px;
}

.drawer-menu-wraper {
  background-image: var(--okx-menu-background-image);
  background-color: var(--okx-menu-background); 
  background-position: center; 
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: auto;
}

.drawer-menu .nav-logo {
  position: absolute;
  left: 0;
  background-position: center;
  top: 15px;
  width: calc(100% - 140px);
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 70px;
}
.web .drawer-menu .sidebar-menu-content {
  --ion-color-base: transparent;
}

.web .drawer-menu .sidebar-menu-content:first-of-type {
  --padding-start: 0px;
}

.web .drawer-menu .sidebar-menu-content:first-of-type ion-item {
  /* --padding-start: var(--okx-drawer-padding); */
  --background-hover: transparent;
}

.web .drawer-menu .sidebar-menu-content:first-of-type ion-item.okx-nav-selected ion-icon {
  background-color: var(--okx-menu-icon-color);
  stroke: var(--okx-menu-background);
  border-radius: 50%;
}

.web .drawer-menu .sidebar-menu-content:first-of-type ion-item.okx-nav-selected ion-icon {
  stroke: var(--ion-color-white);
}

.web
  .drawer-menu
  .sidebar-menu-content:first-of-type
  ion-item.nav-item.small-text.okx-nav-selected:after {
  width: 22px;
  left: 29px;
  top: 0px;
  bottom: 0px;
}
.sidebar-menu-content {
  overflow: auto;
  padding: 0 var(--ion-padding);
  background-color: var(--okx-menu-background);
  flex: 1;
}
.drawer-menu ion-content:first-of-type ion-item.nav-item.small-text ion-icon {
  display: none;
}
.drawer-menu ion-content:first-of-type ion-item.nav-item.small-text {
  padding-left: 6px;
}

.drawer-menu-order-button {
  margin: 0;
  width: 100%;
}

.drawer-menu .nav-icon {
  height: 18px;
  width: 18px;
  margin: 0 5px 0px 0;
  padding: 5px;
}
.web .drawer-menu .nav-title {
  margin: 15px 0 8px 0;
  
}
.drawer-menu .nav-title {
  display: block;
  margin: 15px 0 5px 5px;
  color: var(--okx-menu-title-color) !important;
  font-size: var(--okx-subtitle-font-size);
  font-family: var(--ion-font-family-bold) !important;

}
.web .small-drawer-header {
  padding: 15px 0 0 0;
}

.web .small-drawer-header ion-buttons {
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.web .small-drawer-header ion-button {
  --padding-top: 12px;
  --padding-end: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 48px;
  height: 48px;
}

.web .small-drawer-header ion-button ion-icon {
  font-size: 1.8rem;
  color: var(--okx-color-black);
}

.web .collapse-drawer-icon {
  display: block;
  position: absolute;
  top: 50%;
  right: var(--ion-padding);
  transform: translate(0%, -50%);
  cursor: pointer;
  font-size: 25px;
  color: var(--okx-box-wrapper-color);
}

.web ion-content.log-status {
  display: block;
  max-height: 115px;
}

.web .log-status-wrap {
  background: var(--ion-color-white);
  border-radius: var(--okx-box-radius);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  min-height: 56px;
  margin-top: 5px;
}

.web .log-status-wrap ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
}

.web .log-status-wrap ion-item:first-of-type {
  --background: var(--ion-color-white);
  --border-radius: var(--okx-box-radius);
  backface-visibility: hidden;
  z-index: 2;
}

.web .log-status-wrap ion-item:last-of-type {
  border-bottom-left-radius: var(--okx-box-radius);
  border-bottom-right-radius: var(--okx-box-radius);
  /* border-top: 1px solid var(--okx-color-black); */
  --min-height: 35px;
}

.web .log-status-wrap ion-item.logout-box {
  border-top: 1px solid var(--okx-color-black);
}

.web .log-status-wrap ion-icon {
  color: var(--okx-color-black);
}

.web .logout-box > ion-label,
.web .logout-box > ion-label > ion-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.web .logout-box > ion-label > ion-icon {
  font-size: 17px;
  margin-right: 5px;
}

.web .logout-box > ion-label > ion-text .normal-text {
  font-size: 15px;
  margin-left: 5px;
}

.web .logout-box {
  margin-top: -40px;
  transition: all 0.3s ease;
}

.web .logout-box.visible {
  margin-top: 0;
}
.fiveloyalty-logo-wrapper {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0 10px 0;
  justify-content: flex-start;
  margin-left: 5px;
}
.fiveloyalty-logo-wrapper .small-text {
  color: var(--okx-box-wrapper-color);
}
.web .fiveloyalty-logo-wrapper {
  padding: var(--ion-padding) 0;
}
.fiveloyalty-logo-wrapper + ion-label {
  padding-bottom: var(--ion-padding);
  display: block;
  font-size: var(--okx-small-text-font-size);
  color: var(--okx-menu-color);
}
.fiveloyalty-logo-wrapper img {
  height: 30px;
  margin-top: 10px;
}
.drawer-menu-order-button {
  --background: var(--okx-menu-button-background);
  color: var(--okx-menu-button-text);
}
.external-icon {
  line-height: 0;
}
.external-icon svg {
  height: 25px;
  width: 25px;
  stroke: var(--okx-menu-icon-color);
  padding: 5px;
  margin-right: 5px;
}
.icon-placeholder {
  z-index: 5;
}
.icon-placeholder ion-icon {
  pointer-events: none;
}
.drawer-footer {
  bottom: 0;
  padding: 0 var(--ion-padding);
}