.table-number-input {
	--padding-start: 0;
}
.route-browse-menu .absolute-content {
	padding: 0;
}
.route-browse-menu .click-and-collect-title {
	padding: var(--ion-padding);
	flex: unset;
}
.route-browse-menu .browse-menu-locations {
	height: 60%;
	overflow: auto;
	margin-bottom: 20px;
}
.route-browse-menu .click-and-collect-time {
	height: 60%;
}
.order-to-table-locations {
	padding: 0 var(--ion-padding);
	/* height: 70%; */
}
.route-browse-menu .browse-menu-locations .box-wrapper {
	padding: 0;
	margin: 0 var(--ion-padding);
	cursor: pointer;
}
.route-browse-menu .click-and-collect-button-wrapper {
	padding: var(--ion-padding);
	flex: unset;
}
.unclickable {
	pointer-events: none;
}
.menu-item-wrapper {
	padding: 10px;
}
.menu-item-wrapper ion-item{
	padding: 0 !important;
}
.menu-item-wrapper:not(:last-child) {
	border-bottom: 1px solid var(--ion-color-gray);
}
.menu-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.menu-item > div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.menu-item ion-icon  {
	font-size: 25px;
	pointer-events: none;
}
.menu-item ion-item{
	border-bottom: none !important;
	width: 100%;
}
.menu-times {
  margin-left: 40px;
}
.menu-day {
  display: inline-block;
  width: 40px;
}
.forward-arrow-icon  {
	position: relative;
	left: 7px;
	pointer-events: none;
}