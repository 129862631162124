
.web #main > ion-content.route-terms  > .ion-padding {
  padding-right: 0!important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.web .route-terms  .ion-padding > div {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 10px;
}


.route-terms .page-content *:not(.title) {
  color: var(--okx-box-wrapper-color) !important;
  font-family:var(--ion-font-family)
}
