.service-charge-incrementer-wrapper {
  padding: 20px 0;
  margin: 20px 0;
  background-color: var(--ion-color-white);
}
.service-charge-incrementer-wrapper p {
  text-align: center;
}
.service-charge-incrementer-wrapper .incrementer-note{
  font-size: 25px;
  font-weight: bold;
  color: var(--ion-color-primary);
}
.incrementer-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 0 var(--ion-padding);
  position: absolute;
  width: 100%;
}
.service-charge-modal  .modal-classic-closer{
  display: none;
}
.service-charge-modal .absolute-content,
.service-charge.absolute-content {
  padding: 30px 0;
}
.service-charge .incrementer {
  padding: 0 var(--ion-padding);
}
.service-charge-content .incrementer-quantity-value{
  color: var(--ion-color-primary) !important
}
.service-charge-incrementer-wrapper .incrementer-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.service-charge .incrementer ion-button {
  height: 44px;
  width: 44px;
  margin: 10px;
  --border-color: transparent;
  --border-style:solid;
}
.service-charge .title,
.service-charge .normal-text,
.service-charge-buttons {
  margin: 0 var(--ion-padding);
  display: block;
}

.service-charge-modal .modal-classic-content,
.service-charge-modal .modal-classic-wrapper {
  padding: 5px 13px !important;
}
.service-charge-item {
  pointer-events: none;
}
.route-order .service-charge-item,
.route-order-summary .service-charge-item {
  cursor: pointer;
  pointer-events: all;
}


ion-modal.modal-classic.service-charge-modal {
	--height: 50%;
	--width: 80%;
}
.web ion-modal.modal-classic.service-charge-modal {
	--height: 250px !important;
	--width: var(--okx-bsmodal-width-lg);
}