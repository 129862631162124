/* behavior */

.item-details-image {
  position: absolute;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  /* next line is portrait-only */
  height: 100vw;
  background: transparent none no-repeat 50% 0;
  background-size: cover;
}

.item-details-card-header h2,
.item-details-card-header h3,
.item-details-card-header h4 {
  margin-top: 0;
}

.item-details-actions {
  z-index: 5;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

/* end behavior */

/* content reset */

.route-item-details ion-button.item-details-add-to-order {
  margin-top: 0;
  margin-bottom: 0;
  flex: 1 1 auto;
  /*margin-left: 0;*/
  --padding-start: 8px;
  --padding-end: 8px;
}

.item-details-add-to-order.disabled {
  opacity: 0.5;
}

/* end content reset */

/* design */

.item-details-card {
  background-color: var(--okx-box-wrapper-background);
  border-top-left-radius: var(--ion-card-radius);
  border-top-right-radius: var(--ion-card-radius);
}

.item-details-card-header {
  padding: 10px var(--ion-padding) 10px;
  z-index: 1;
}

.item-details-card-header .title {
  font-weight: bold;
  margin: 0;
}

.item-details-card-content .sectiontitle {
  margin: 0;
}

.item-details-card-description,
.item-details-card-description p,
.item-details-card-description p span {
  color: var(--ion-color-secondary) !important;
  background: transparent !important;
}

span.item-details-card-description {
  display: inline-block;
  margin: 0 0 20px;
  /* margin: 5px 0 15px 0;
  font-size: 13px; */
}

.menu-deal-group-wrapper {
  margin-top: 20px;
}

.item-details-card-price {
  text-align: right;
  font-size: var(--okx-subtitle-font-size);
  font-weight: bold;
  color: var(--okx-box-wrapper-color);
}

.item-details-card-content .item-details-card-list {
  padding: 0 var(--ion-padding);
}

.web .item-details-card-content .item-details-card-list {
  padding: 0;
}

.item-details-actions {
  background-color: var(--okx-box-wrapper-background);
  padding: 20px;
}

.item-details-actions ion-button {
  margin-top: 0;
  margin-bottom: 0;
}

.item-details-actions .incrementer {
  flex: 0 0 85px;
  padding: 10px;
  border-radius: calc(2 * var(--okx-box-radius));
  border: 1px solid var(--ion-color-gray);
}
.route-order .item-content-actions .incrementer,
.route-order-summary .item-content-actions .incrementer {
  display: flex;
}
.item-content-actions .incrementer {
  flex: 0 0 40px;
  padding: 5px;
  border-radius: calc(2 * var(--okx-box-radius));;
  border: 1px solid var(--ion-color-gray);
  display: none;
}
.item-content-actions .incrementer-quantity-solo,
.item-content-actions .incrementer-quantity {
  flex-basis: 15px;
  margin: 0 10px !important;
  padding: 0 !important;
  display: flex;
}
.item-content-actions
  .incrementer-quantity:not(.incrementer-quantity-solo)
  .incrementer-quantity-value {
  display: none;
}
.item-details-actions .incrementer-quantity-solo {
  flex-basis: 30px;
  color: var(--ion-color-secondary);
}

.item-details-actions-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-details-remove,
.item-details-add {
  flex: 0 0 40px;
  height: 40px;
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-light);
  --background: #fff;
  --background-hover: var(--ion-color-light);
  --color: var(--ion-color-primary);
}

.item-details-quantity {
  flex: 0 0 75px;
  text-align: center;
}

.item-details-background {
  background: var(--ion-color-gray);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  /* next line is portrait-only */
  opacity: 0.7;
  height: 100vw;
}

/*
ion-button.item-details-add-to-order.greyed {
	--background: var(--ion-color-gray-tint);
}
*/

.item-details-product-label {
  line-height: normal;
}

.route-item-details .okx-pullup-content {
  padding-bottom: 20px;
}

.route-item-details .native-textarea.sc-ion-textarea-md,
.route-item-details .native-textarea.sc-ion-textarea-ios {
  transform: none !important;
  -webkit-transform: none !important;
}

.sub-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-increment-content p {
  text-align: end;
  margin: 0;
  margin-bottom: 5px;
}

.incrementer ion-button {
  --border-radius: 50%;
  --color: var(--ion-color-primary-contrast);
  flex: unset;
  --background: var(--ion-color-primary);
  min-height: auto;
  height: 23px;
  width: 23px;
}

.incrementer .incrementer-quantity-solo {
  margin: 0 20px;
}

/* 

.incrementer-box {
    width: 130px;
   padding-top: 10px; 
    align-items: flex-start;
    height: 100%;
    margin-top: -15px;
}
.incrementer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.price-increment-content .incrementer ion-button {
    --border-radius: 50%;
    width: 25px;
    height: 25px;
	flex: unset;
}
.incrementer-quantity-solo {
    flex: 0 0 45px;
    text-align: center;
} */
/* .route-item-details .textarea-unfocused .native-textarea.sc-ion-textarea-md,
.route-item-details .textarea-unfocused .native-textarea.sc-ion-textarea-ios {
	transform: translate3d(0,0,0) !important;
	-webkit-transform: translate3d(0,0,0) !important;
} */

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .item-details-image {
    position: absolute;
  }
}

/* end design */
/*
.web .okx-pullup {
	top: 120px !important;
	bottom: 50px !important;
	transform: none !important;
	left: 50%;
	right: auto;
	width: 400px;
	margin-left: -200px;
	border-radius: var(--okx-box-radius);
}

.web .notch {
	display: none;
}

.web .okx-pullup-content {
	bottom: 140px !important;
}

.web .item-details-actions {
	left: calc(50%);
	width: 350px;
	margin-left: -175px;
	bottom: 60px;
}
*/

.item-details-header {
  display: flex;
  padding: calc(2 * var(--ion-padding)) var(--ion-padding) var(--ion-padding) 15px;
  position: absolute;
  top: 0;
  z-index: 6;
  width: 100%;
}

.item-details-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px var(--ion-padding);
}

.earns-loyalty-stamps {
  display: flex;
  border-radius: 25px;
  border: 1px solid var(--ion-color-gray);
  padding: 1px 5px;
}

.earns-loyalty-stamps ion-icon {
  height: var(--okx-title-font-size);
  width: var(--okx-title-font-size);
  padding-right: 5px;
}

.earns-loyalty-stamps ion-text {
  display: flex;
  align-items: center;
}

.earns-loyalty-stamps ion-text .small-text{
  font-weight: bold;
  color: var(--ion-color-primary);
}

.item-details-header.active {
  background-color: var(--okx-box-wrapper-background);
}

.item-details-header ion-button {
  margin: 0;
  width: 40px;
  height: 40px;
  --border-radius: 50%;
  --padding-end: 0;
  --padding-start: 0;
}

.item-details-img {
  height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: var(--ion-color-gray);
}

.item-details-name::before {
  content: '';
  height: 30px;
  background-color: var(--okx-box-wrapper-background);
  border-top-left-radius: calc(var(--okx-box-radius) * 2);
  border-top-right-radius: calc(var(--okx-box-radius) * 2);
  position: absolute;
  top: -28px;
  left: 0;
  z-index: 5;
  width: 100%;
}

.item-details-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-placeholder {
  height: calc(var(--ion-padding) * 2 + 30px);
}

.web .image-placeholder {
  height: 50px;
}

.item-details-description {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px;
}

.item-details-name {
  padding: 0 var(--ion-padding) 0;
  position: relative;
}

.item-details-main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.sub-item ion-item {
	flex: 1;
}

.sub-item .product-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.special-instructions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.special-instructions-modal-button {
  display: flex;
  align-items: flex-end;
  flex: 1;
}

.special-instructions-modal-button ion-button {
  width: 100%;
}

.special-instructions-modal .modal-classic-content {
  display: flex;
  flex-direction: column;
}

.web .special-instructions-modal .modal-classic-content {
  justify-content: flex-start !important;
}

.special-instructions-wrapper ion-icon {
  margin-left: 10px;
}

.special-instructions-wrapper ion-button {
  height: fit-content;
  margin: 0;
}

.add-instructions-button {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
}

.special-instructions-modal .modal-wrapper {
  border-radius: 0;
}

.web .special-instructions-modal .modal-wrapper {
  border-radius: var(--border-radius);
}

.special-instructions-modal {
  --height: 100% !important;
  --width: 100% !important;
}

.web .special-instructions-modal {
  --height: 80% !important;
  --width: 500px !important;
}

.special-instructions-wrapper .normal-text {
  white-space: pre-wrap;
}

.item-details-group-wrapper {
  border: 1px solid var(--ion-color-gray);
  border-radius: var(--okx-box-radius);
  overflow: hidden;
  margin-bottom: 20px;
}

.item-details-group-wrapper .sub-item {
  background-color: var(--okx-box-wrapper-background); 
  border-bottom: 1px solid var(--ion-color-gray);
  padding:  10px;
}

.item-details-group-wrapper .sub-item .normal-text,
.item-details-group-wrapper .sub-item .small-text {
  color: var(--okx-box-wrapper-color);
}

/* .web .item-details-group-wrapper ion-item {
  padding: 0 10px;
} */
.item-details-group-wrapper ion-item:last-of-type,
.item-details-group-wrapper .sub-item:last-of-type {
  border-bottom: 0;
}

.checkbox-placeholder {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid var(--ion-color-primary);
  margin-right: 20px;
}

.item-detals-background {
  background-color: var(--okx-box-wrapper-background);
  height: 100%;
}

.item-details-card-description,
.item-details-card-description p,
.item-details-card-description p span,
.item-details-card-content .subtitle {
  color: var(--okx-box-wrapper-color) !important;
}
.add-extra-list {
  overflow-y: auto;
}
.add-extra-modal .ion-page {
  padding: var(--ion-padding);
}
.web .add-extra-modal .modal-wrapper {
  --background: var(--okx-box-wrapper-background);
}
.product-title {
  font-weight: bold !important;
	font-family: var(--ion-font-family-medium) !important;
}
